<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-layer-group" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="tile-title">{{item.amount * item.quantity | currency}}</div>
            <div class="meta" v-if="item.first_name"><i class="far fa-fw fa-user mr-2"></i>{{item.first_name}} {{item.last_name}}</div>
            <div class="meta" v-if="item.trip_title"><i class="far fa-fw fa-tennis-ball mr-2"></i>{{item.trip_title}}</div>
            <div class="meta"><i class="far fa-fw fa-align-left mr-2"></i>{{item.title}}</div>
            <div class="meta"><i class="far fa-fw fa-calendar-day mr-2"></i>Due {{item.due_on | moment('MMMM Do, YYYY')}}</div>
        </template>
        <template v-slot:status>
            <div v-if="item.paid==1"><b-tooltip label="Paid" position="is-left"><i class="fas fa-check"></i></b-tooltip></div>
            <div v-else><b-tooltip label="Not Paid" position="is-left"><i class="fas fa-ban"></i></b-tooltip></div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop=""><i class="far fa-pencil"></i></button>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="Type">{{item.type | capitalize}}</data-row>
                    <data-row mini title="Amount">{{item.amount | currency}}</data-row>
                    <data-row mini title="Quantity">{{item.quantity}}</data-row>
                    <data-row mini title="Created">{{item.created_at | moment('MMMM Do, YYYY h:mm a')}}</data-row>
                    <data-row mini title="Updated" v-if="item.updated_at">{{item.updated_at | moment('MMMM Do, YYYY h:mm a')}}</data-row>
                    <data-row mini title="ID">{{item.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'PlanItemListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            item: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .tile-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }

    .t-badge {
        background-color: $t-primary;
        border-radius: 4px;
        color: white;
        padding: 2px 7px 0;
        font-size: .7rem;
    }
</style>
