<template>
    <div>
        <card-header title="Plan Items Past Due" icon="fa-history"/>
<!--        <card-toolbar>-->
<!--            <button><i class="fas fa-user-plus mr-2"/>New Customer</button>-->
<!--        </card-toolbar>-->

        <card-body>
            <card-list>
                <subheader-list-item title="Due Plan Items" icon="fas fa-history" :badge="due.length"/>
                <plan-item-list-item
                    :card="card"
                    v-for="i in due"
                    :item="i"
                    @click="$openCard('make-payment', {planId: i.plan_id}, card)"
                    :active="child && child.definition.component === 'make-payment' && child.props.planId === i.plan_id.toString()"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CustomerListItem from "../../components/CustomerListItem";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import CardBody from "@/TIER/components/CardBody";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import {client as http} from "@/http_client";
    import UserListItem from "@/components/CustomerListItem";
    import async from "async";
    import PlanItemListItem from "../../components/PlanItemListItem";

    export default {
        name: "Users",
        props: ['card', 'child'],
        components: {PlanItemListItem, SubheaderListItem, CardBody, SearchListItem, CardToolbar, CustomerListItem, CardList, CardHeader},
        data: function() {
            return {
                due: []
            };
        },
        methods: {
            loadBilling(force) {
                this.$emit('loading', true);

                async.parallel([
                    (cb) => {
                        http.get('/api/billing/due', {force}).then(response => {
                            this.due = response.data;
                            cb();
                        });
                    },
                    // (cb) => {
                    //     http.get('/api/users/created', {force}).then(response => {
                    //         for (let i = 0; i < response.data.length; i++) {
                    //             response.data[i].active = false;
                    //         }
                    //         this.newCustomers = response.data;
                    //         cb();
                    //     });
                    // }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadBilling(true);
            }
        },
        mounted() {
            this.loadBilling();
        }
    };
</script>

<style lang="scss" scoped>

</style>
